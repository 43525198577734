<template>
  <!-- copyright -->
  <div>
    <div class=" copyright fs-20  "
      :class="type == 1 ? 'space-between color5 opacity5' : 'textcenter color10 column-reverse'">
      <p @click="jump">Copyright©️by nineton 渝ICP备2023000159号-2 </p>

      <div class="right ac fs-20 " :class="type == 1 ? 'color5' : 'jc color10 mar-bottom6'">
        <div class="item">
          微信公众号
          <!-- 二维码弹窗 -->
          <div class="qrcode">
            <div class="textcenter box">
              <img src="https://rwstatic.cheeseword.com/web/img/53.jpg" class="img" alt="">
              <div class="fs-12">
                使用微信扫码关注
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <a :class="type == 1 ? 'color5' : 'color10'" href="https://rwstatic.cheeseword.com/package/nldcyhxy.html"
            target="_blank">用户协议</a>
        </div>
        <div class="item">
          <a :class="type == 1 ? 'color5' : 'color10'" href="https://rwstatic.cheeseword.com/package/andr_ndyszc.html"
            target="_blank">隐私政策</a>
        </div>
        <div class="item">
          联系我们
          <div class="contact fs-16 color5">
            <p>意见反馈：support@cheeseword.com</p>
            <p>
              商务合作：bd@cheeseword.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '1'
    }
  },
  methods: {
    jump() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style scoped lang="less">
.column-reverse {
  display: flex;
  flex-flow: column-reverse;
}

.copyright {
  width: 1200px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  bottom: 40px;
  z-index: 10001;

  span {
    cursor: pointer;
  }

  .right {
    .item {
      margin-right: 20px;
      position: relative;
      cursor: pointer;

      .contact {
        width: 330px;
        height: 114px;
        background-image: url(https://rwstatic.cheeseword.com/web/img/73.png);
        background-size: 100% 100%;
        position: absolute;
        bottom: 10px;
        left: -200px;
        padding: 27px 0 0 25px;
        box-sizing: border-box;
        display: none;
      }

      &:nth-last-child(1):hover .contact {
        display: block;
        cursor: text;
      }

      a {
        text-decoration: none;
      }

      &:nth-child(1) {
        &:hover .qrcode {
          display: flex;
        }
      }

      .qrcode {
        width: 145px;
        height: 161px;
        background-image: url(https://rwstatic.cheeseword.com/web/img/29.png);
        background-size: 100% 100%;
        position: absolute;
        z-index: 1000;
        bottom: 10px;
        display: none;

        .box {
          margin: 0 auto;
          padding-top: 15px;
          box-sizing: border-box;
        }

        .img {
          width: 99px;
          height: 88px;
          // margin-bottom: 5px;
        }
      }

      &:nth-last-child(1) {
        &::after {
          content: '';
        }
      }

      &::after {
        content: '|';
        position: absolute;
        margin-left: 10px;
      }
    }
  }
}
</style>